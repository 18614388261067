import React, { useState, useContext, useEffect } from "react";
import axios from "axios";
import { navigate } from "@reach/router";
import { Link } from "gatsby";

import { cartContext } from "providers/CartProvider";
import { isoToDate, formatToMilitaryDate } from "helpers";
import { StyledClassUser, StyledClassView } from "./styles";

const ViewOrder = ({ ordernum }) => {
  const siteContext = useContext(cartContext);
  const [initialData, setInitialData] = useState({});
  const [refresher, setRefresher] = useState("");

  useEffect(() => {
    async function fetchData(orderNum) {
      try {
        const token = siteContext.userToken;

        const response = await axios.get(
          `${process.env.GATSBY_API_URL}/admin-tools/order/${orderNum}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
            validateStatus(status) {
              return status < 500; // Resolve only if the status code is less than 500
            },
          }
        );

        const formattedDate = isoToDate(response.data[0].created_at);
        response.data[0].created_at = formattedDate;

        setInitialData(response.data);
      } catch (e) {
        siteContext.makeToast("Unable to fetch order details.", "error");
      }
    }

    fetchData(ordernum);
  }, [siteContext, ordernum, refresher]);

  const handleSendManualEmail = async values => {
    try {
      const token = siteContext.userToken;

      const response = await axios.post(
        `${process.env.GATSBY_API_URL}/admin-tools/resend-manual`,
        values,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          validateStatus(status) {
            return status < 500; // Resolve only if the status code is less than 500
          },
        }
      );

      if (response && response.status === 200) {
        siteContext.makeToast("Email with manual link sent to user.");
      } else {
        siteContext.makeToast("Email was not sent.");
      }
    } catch (e) {
      return siteContext.makeToast(
        "Problem sending email. Wait a moment and try again, or contact Daniel.",
        "error"
      );
    }
  };

  const handleDeleteOrder = async orderID => {
    if (
      !window.confirm(
        "Are you sure you want to delete this item from the order?"
      )
    ) {
      return;
    }
    // console.log("ViewOrder -> orderID", orderID);

    try {
      const token = siteContext.userToken;

      const response = await axios.delete(
        `${process.env.GATSBY_API_URL}/admin-tools/order/${orderID}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          validateStatus(status) {
            return status < 500; // Resolve only if the status code is less than 500
          },
        }
      );

      if (response.status === 200) {
        setRefresher(orderID);
        return siteContext.makeToast("Item deleted from order.", "success");
      }

      return siteContext.makeToast("Item was not removed from order.", "error");
    } catch (e) {
      return siteContext.makeToast(
        "Could not remove item from order.",
        "error"
      );
    }
  };

  const calcTotalPaid = items => {
    const total = items.reduce(
      (tot, curr) => tot + Number(curr.schedule.price) * curr.qty,
      0
    );

    return `$${Number(total).toFixed(2)}`;
  };

  return (
    <div>
      <h2 className="mb-4">Admin: View Order</h2>

      <p className="my-3">
        <Link to="/admin/dashboard/orders" className="btn btn-success">
          Back To List
        </Link>{" "}
        {/* <Link
          to={`/admin/dashboard/orders/edit/${id}`}
          className="btn btn-info"
        >
          Edit This Item
        </Link> */}
      </p>
      {initialData.length > 0 && initialData[0].ordernum && (
        <StyledClassView>
          <h4>Order #: {initialData[0].ordernum}</h4>
          <p style={{ fontSize: "15px", lineHeight: 1.5 }}>
            <b>Purchase Date:</b>{" "}
            {formatToMilitaryDate(initialData[0].created_at)} <br />
            <b>Total:</b> {calcTotalPaid(initialData)}
          </p>

          <hr />
          {/* <p>Total Paid: ${initialData.price}</p> */}
          <div className="mt-4">
            <h5>Items Purchased:</h5>

            {initialData.map((order, i) => {
              const { schedule } = order;

              return (
                <StyledClassUser key={i} className="card p-2 mb-2">
                  <div className="details">
                    <p>
                      <b>{schedule.title}</b> <br />
                      <b>Start Date:</b> {formatToMilitaryDate(schedule.date)}{" "}
                      <br />
                      <b>Price:</b> ${schedule.price} <br />
                      <b>Qty:</b> {order.qty}
                    </p>
                  </div>

                  <div className="attendees">
                    <h6>Attendees:</h6>
                    {order.attendees &&
                      order.attendees.map((row, z) => (
                        <p key={z}>
                          Name: {row.name}, Email: {row.email},{" "}
                          {schedule.manual_url && (
                            <button
                              type="button"
                              className="btn btn-info btn-sm"
                              onClick={() => {
                                const data = {
                                  email: row.email,
                                  id: schedule.id,
                                  name: row.name,
                                };
                                handleSendManualEmail(data);
                              }}
                            >
                              send manual
                            </button>
                          )}
                        </p>
                      ))}
                  </div>

                  <button
                    type="button"
                    className="btn btn-danger btn-sm"
                    onClick={() => handleDeleteOrder(order.orderid)}
                  >
                    remove
                  </button>
                </StyledClassUser>
              );
            })}
          </div>

          {/* Qty Purchased: {user._pivot_qty} */}

          <hr />

          <h5>Purchased By:</h5>
          {initialData[0].user ? (
            <StyledClassUser>
              <h6>
                <Link
                  to={`/admin/dashboard/users/edit/${initialData[0].user.id}`}
                >
                  {initialData[0].user.namelast},{" "}
                  {initialData[0].user.namefirst}{" "}
                </Link>
              </h6>
              <p>
                Email:{" "}
                <a href={`mailto:${initialData[0].user.email}`}>
                  {initialData[0].user.email}
                </a>
              </p>
            </StyledClassUser>
          ) : (
            <p>No users currently enrolled here.</p>
          )}
        </StyledClassView>
      )}
    </div>
  );
};

export default ViewOrder;
