import React, { useState, useContext, useEffect } from "react";
import axios from "axios";
import { navigate } from "@reach/router";
import { Link } from "gatsby";

import AdminScheduleForm from "components/forms/AdminScheduleForm";
import { cartContext } from "providers/CartProvider";

const AddSchedule = ({ id }) => {
  const siteContext = useContext(cartContext);
  const [cats, setCats] = useState([]);

  useEffect(() => {
    async function fetchCats() {
      try {
        const response = await axios.get(
          `${process.env.GATSBY_API_URL}/courses/coursecats`
        );
        setCats(response.data);
      } catch (e) {
        siteContext.makeToast("Error fetching categories.", "error");
      }
    }

    fetchCats();
  }, [siteContext, id]);

  const handleSubmit = async values => {
    const response = await axios.post(
      `${process.env.GATSBY_API_URL}/admin-tools/schedule`,
      values,
      {
        headers: {
          Authorization: `Bearer ${siteContext.userToken}`,
        },
        validateStatus(status) {
          return status < 500; // Resolve only if the status code is less than 500
        },
      }
    );

    if (response && response.status === 200) {
      siteContext.makeToast(
        "Schedule added. Don't forget to rebuild the site after making all your changes!",
        "success"
      );
      return navigate("/admin/dashboard/schedules");
    }

    const errorMsg = response.data || "There was a problem.";
    return siteContext.makeToast(errorMsg, "error");
  };

  return (
    <div>
      <h2>Admin: Add Schedule Item</h2>

      <p className="mt-3">
        <Link to="/admin/dashboard/schedules" className="btn btn-success">
          Cancel
        </Link>
      </p>

      <AdminScheduleForm submitIt={handleSubmit} cats={cats} />
    </div>
  );
};

export default AddSchedule;
