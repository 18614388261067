import React, { useState, useMemo } from "react";
import { Link } from "gatsby";
import DataTable from "react-data-table-component";
import memoize from "memoize-one";
import { json2csvAsync } from "json-2-csv";

import { isoToDate, formatToMilitaryDate } from "helpers";

// Blatant "inspiration" from https://codepen.io/Jacqueline34/pen/pyVoWr
async function downloadCSV(array) {
  const link = document.createElement("a");

  // format 'users' and 'coursecats' into just IDs here
  const newArray = array.slice().map(
    row =>
      // row.users = row.users.map(x => x.id);
      // if (row.users.length === 0) {
      //   row.users = "";
      // }

      // row.coursecats = row.coursecats.map(x => x.title).toString();
      // if (Array.isArray(row.coursecats)) {
      //   row.coursecats = row.coursecats
      //     .map(x => `id:${x.id}|title:${x.title}`)
      //     .toString();
      // }
      // if (Array.isArray(row.users)) {
      //   row.users = row.users.map(x => `id:${x.id}|email:${x.email}`).toString();
      // }
      row
  );
  let csv = await json2csvAsync(newArray, {
    // expandArrayObjects: true,
    // keys: ["namelast", "namefirst", "email", "schedules"],
  });
  if (csv == null) return;

  const filename = "export.csv";

  if (!csv.match(/^data:text\/csv/i)) {
    csv = `data:text/csv;charset=utf-8,${csv}`;
  }

  link.setAttribute("href", encodeURI(csv));
  link.setAttribute("download", filename);
  link.click();
}

const Export = ({ onExport }) => (
  <div className="ml-4">
    <button
      type="button"
      className="btn btn-info"
      onClick={e => onExport(e.target.value)}
    >
      Export CSV
    </button>
  </div>
);

const delCell = memoize((row, handleDelete) => (
  <button
    type="button"
    className="btn btn-sm btn-danger"
    onClick={() => handleDelete(row.ordernum)}
  >
    Delete
  </button>
));

const viewCell = row => (
  <Link
    to={`/admin/dashboard/orders/view/${row.ordernum}`}
    className="btn btn-sm btn-info"
  >
    View
  </Link>
);

const userCell = row => (
  <Link
    to={`/admin/dashboard/users/edit/${row.user.id}`}
    state={{ userData: row }}
    className="text-info"
  >
    {row.user.namelast}
  </Link>
);

const scheduleCell = row => (
  <Link
    to={`/admin/dashboard/schedules/view/${row.schedule.id}`}
    className="text-info"
  >
    {row.schedule.title}
  </Link>
);

const FilterComponent = ({ filterText, onFilter, onClear }) => (
  <div className="input-group" style={{ width: "300px" }}>
    <input
      id="search"
      type="text"
      placeholder="Filter By Name or Order#"
      value={filterText}
      onChange={onFilter}
      className="form-control"
    />
    <div className="input-group-append">
      <button type="button" onClick={onClear} className="btn btn-secondary">
        X
      </button>
    </div>
  </div>
);

const columns = memoize(handleDelete => [
  {
    name: "Purchase Date",
    selector: "created_at",
    sortable: true,
    cell: row => formatToMilitaryDate(row.created_at),
  },
  {
    name: "Order #",
    selector: "ordernum",
    sortable: true,
  },
  {
    name: "User",
    selector: "user_id",
    sortable: true,
    cell: row => userCell(row),
  },
  {
    name: "View/Edit",
    button: true,
    cell: row => viewCell(row),
    width: "70px",
  },
  {
    name: "Delete",
    button: true,
    cell: row => delCell(row, handleDelete),
    width: "70px",
  },
]);

const SchedulesDataTable = ({ data, handleDelete }) => {
  const [filterText, setFilterText] = useState("");
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);

  // const actionsMemo = useMemo(
  //   () => <Export onExport={() => downloadCSV(data)} />,
  //   [data]
  // );

  const filteredItems =
    data &&
    data.filter(item => {
      if (item.ordernum.toLowerCase().includes(filterText.toLowerCase())) {
        return true;
      }

      if (item.user.namelast.toLowerCase().includes(filterText.toLowerCase())) {
        return true;
      }

      if (!filterText) return true;

      return false;
    });

  const subHeaderComponentMemo = useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText("");
      }
    };

    return (
      <>
        <FilterComponent
          onFilter={e => setFilterText(e.target.value)}
          onClear={handleClear}
          filterText={filterText}
        />
        <Export onExport={() => downloadCSV(data)} />
      </>
    );
  }, [filterText, resetPaginationToggle, data]);

  return (
    data && (
      <DataTable
        title="Orders"
        columns={columns(handleDelete)}
        data={filteredItems}
        pagination
        paginationResetDefaultPage={resetPaginationToggle} // a hook to reset pagination to page 1
        subHeader
        subHeaderComponent={subHeaderComponentMemo}
        responsive
        // noTableHead
        noHeader
        // actions={actionsMemo}
      />
    )
  );
};

export default SchedulesDataTable;
