import React, { useState, useContext, useEffect } from "react";
import { navigate } from "@reach/router";
import { Helmet } from "react-helmet";

import UserLoginForm from "components/forms/UserLoginForm";
import { handleAdminLogin, isLoggedInAdmin } from "services/auth";
import { cartContext } from "providers/CartProvider";

const Login = props => {
  const siteContext = useContext(cartContext);

  useEffect(() => {
    if (siteContext && isLoggedInAdmin(siteContext)) {
      navigate(`/admin/dashboard/`);
    }
  }, [siteContext]);

  const handleSubmit = async inputs => {
    try {
      const response = await handleAdminLogin(inputs, siteContext);
    } catch (e) {
      siteContext.makeToast("Error logging in.", "error");
    }
  };

  const handleConfirm = (vals, actions) => {
    if (window.grecaptcha) {
      window.grecaptcha.ready(function () {
        window.grecaptcha
          .execute(process.env.GATSBY_RECAPTCHA_PUBLIC, {
            action: "adminlogin",
          })
          .then(function (token) {
            // console.log("token: ", token);
            handleSubmit({ token, ...vals }, actions);
          });
      });
    }
  };

  return (
    <div>
      <Helmet>
        <script
          src={`https://www.google.com/recaptcha/api.js?render=${process.env.GATSBY_RECAPTCHA_PUBLIC}`}
        />
      </Helmet>
      <h2>Admin: Login</h2>

      <UserLoginForm submitIt={handleConfirm} />
    </div>
  );
};

export default Login;
