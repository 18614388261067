import React, { useState, useContext, useEffect } from "react";
import axios from "axios";
import { navigate } from "@reach/router";
import { Link } from "gatsby";

import { isoToDate } from "helpers";
import AdminOthereventForm from "components/forms/AdminOthereventForm";
import { cartContext } from "providers/CartProvider";

const EditOtherevent = ({ id }) => {
  const siteContext = useContext(cartContext);
  const [initialData, setInitialData] = useState({});

  useEffect(() => {
    async function fetchData() {
      try {
        // const token = siteContext.userToken;

        const response = await axios.get(
          `${process.env.GATSBY_API_URL}/otherevents/${id}`,
          {
            validateStatus(status) {
              return status < 500; // Resolve only if the status code is less than 500
            },
          }
        );

        // adjust data for our forms
        const formattedDate = isoToDate(response.data.date);
        response.data.date = formattedDate;

        if (response.data.datestop) {
          // test
          const formattedDateStop = isoToDate(response.data.datestop);
          response.data.datestop = formattedDateStop;
        }

        setInitialData(response.data);
        // console.log("fetchData -> response.data", response.data);
      } catch (e) {
        siteContext.makeToast("Unable to fetch event details.", "error");
      }
    }

    fetchData(id);
  }, [siteContext, id]);

  const handleSubmit = async values => {
    // e.preventDefault();

    const response = await axios.put(
      `${process.env.GATSBY_API_URL}/admin-tools/otherevent-update/${id}`,
      values,
      {
        headers: {
          Authorization: `Bearer ${siteContext.userToken}`,
        },
        validateStatus(status) {
          return status < 500; // Resolve only if the status code is less than 500
        },
      }
    );

    if (response && response.status === 200) {
      siteContext.makeToast("Event item updated.", "success");
      return navigate(`/admin/dashboard/otherevents/view/${id}`);
    }
    const errorMsg = response.data || "There was a problem.";
    return siteContext.makeToast(errorMsg, "error");
  };

  return (
    <div>
      <h2>Admin: Edit 'Other Event' Item</h2>

      <p className="mt-3">
        <Link to="/admin/dashboard/otherevents" className="btn btn-success">
          Cancel
        </Link>
      </p>
      {initialData && initialData.title && (
        <AdminOthereventForm submitIt={handleSubmit} initVals={initialData} />
      )}
    </div>
  );
};

export default EditOtherevent;
