import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import { Link } from "gatsby";
import { navigate } from "@reach/router";

import { isLoggedIn } from "services/auth";
import { cartContext } from "providers/CartProvider";

const AdminUpdatePassword = () => {
  const [errors, setErrors] = useState(null);
  const [inputs, setInputs] = useState({
    password: "",
    pconfirm: "",
  });

  const siteContext = useContext(cartContext);

  const handleSubmit = async e => {
    e.preventDefault();
    // console.log("inputs: ", inputs);

    // TODO: add better password strength check
    if (inputs.password.length >= 4) {
      try {
        const response = await axios.put(
          `${process.env.GATSBY_API_URL}/users/update-password`,
          inputs,
          {
            headers: {
              Authorization: `Bearer ${siteContext.userToken}`,
            },
            validateStatus(status) {
              return status < 500; // Resolve only if the status code is less than 500
            },
          }
        );

        if (response && response.status === 200) {
          siteContext.makeToast("Password updated!", "success");
          return navigate("/admin/dashboard", {
            state: { message: "Password updated!" },
          });
        }

        const errorMsg = response.data || "There was a problem.";
        return siteContext.makeToast(errorMsg, "error");
      } catch (err) {
        // console.log("UpdatePassword -> e", err.message);
        siteContext.makeToast(
          "There was a problem changing your password.",
          "error"
        );
      }
    } else {
      return siteContext.makeToast(
        "Password needs to be at least 4 characters. (Will improve validation and requirements soon!)",
        "error"
      );
    }
  };

  const handleInputChange = event => {
    event.persist();
    setInputs(() => ({
      ...inputs,
      [event.target.name]: event.target.value,
    }));
  };

  return (
    <div>
      <h2>Update Password</h2>

      {errors && <p>{errors}</p>}

      <form onSubmit={handleSubmit} className="form">
        <div className="form-group">
          <label htmlFor="password">Password:</label>
          <input
            type="password"
            name="password"
            id="password"
            className="form-control"
            value={inputs.password}
            onChange={handleInputChange}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="passwordconfirm">Type password again:</label>
          <input
            type="password"
            name="pconfirm"
            id="pconfirm"
            className="form-control"
            value={inputs.pconfirm}
            onChange={handleInputChange}
            required
          />
        </div>
        <button type="submit" className="btn btn-lg btn-primary">
          Save Password
        </button>
      </form>
    </div>
  );
};

export default AdminUpdatePassword;
