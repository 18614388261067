import React from "react";
import { Formik, Field, Form, ErrorMessage, FieldArray } from "formik";
import { format } from "date-fns";

import { scheduleValidationSchema } from "helpers/validation";

const AddScheduleForm = ({ submitIt, cats, initVals = null }) => (
  // console.log("initVals: ", initVals);

  <Formik
    initialValues={{
      title: initVals ? initVals.title : "",
      price: initVals ? initVals.price : "",
      date: initVals ? initVals.date : format(new Date(), "yyyy-MM-dd"),
      timestart: initVals ? initVals.timestart : "09:00",
      timeend: initVals ? initVals.timeend : "14:00",
      datestop: initVals && initVals.datestop ? initVals.datestop : "",
      totalseats: initVals && initVals.totalseats ? initVals.totalseats : "",
      note: initVals && initVals.note ? initVals.note : "",
      online: initVals && initVals.online ? initVals.online : false,
      coursecats: initVals ? initVals.coursecats : [],
      manual_url: initVals ? initVals.manual_url : null,
      manual_label: initVals ? initVals.manual_label : null,
      manual_note: initVals ? initVals.manual_note : null,
    }}
    validationSchema={scheduleValidationSchema}
    onSubmit={(values, actions) => {
      // alert(JSON.stringify(values, null, 2));
      submitIt(values);
      actions.setSubmitting(false);
    }}
  >
    {props => (
      <Form className="form">
        <div className="form-row">
          <div className="col-12">
            <div className="form-group">
              <label htmlFor="title">Class Title:</label>
              <Field
                name="title"
                placeholder="Class Title"
                className="form-control"
                required
              />
              <ErrorMessage
                name="title"
                component="div"
                className="text-danger input-feedback"
              />
            </div>
          </div>
        </div>

        <div className="form-row">
          <div className="col-12 col-md-6">
            <div className="form-group">
              <label htmlFor="price">Price:</label>
              <Field
                name="price"
                placeholder="Price"
                className="form-control"
                required
              />
              <ErrorMessage
                name="price"
                component="div"
                className="text-danger input-feedback"
              />
            </div>
          </div>

          <div className="col-12 col-md-6">
            <div className="form-group">
              <label htmlFor="totalseats">Total Seats:</label>
              <Field
                name="totalseats"
                placeholder="Total Seats"
                type="number"
                className="form-control"
              />
              <ErrorMessage
                name="totalseats"
                component="div"
                className="text-danger input-feedback"
              />
            </div>
          </div>
        </div>

        <div className="form-row">
          <div className="col-12 col-md-6">
            <div className="form-group">
              <label htmlFor="date">Start Date:</label>
              <Field
                name="date"
                placeholder="Select Date"
                type="date"
                className="form-control"
                required
              />
              <ErrorMessage
                name="date"
                component="div"
                className="text-danger input-feedback"
              />
            </div>
          </div>

          <div className="col-12 col-md-6">
            <div className="form-group">
              <label htmlFor="timestart">Start Time:</label>
              <Field
                name="timestart"
                className="form-control"
                placeholder="Start Time"
                required
              />
              <ErrorMessage
                name="timestart"
                component="div"
                className="text-danger input-feedback"
              />
            </div>
          </div>
        </div>

        <div className="form-row">
          <div className="col-12 col-md-6">
            <div className="form-group">
              <label htmlFor="datestop">End Date:</label>
              <Field
                type="date"
                name="datestop"
                className="form-control"
                placeholder="End Time"
              />
              <ErrorMessage
                name="datestop"
                component="div"
                className="text-danger input-feedback"
              />
            </div>
          </div>
          <div className="col-12 col-md-6">
            <div className="form-group">
              <label htmlFor="timeend">End Time:</label>
              <Field
                name="timeend"
                className="form-control"
                placeholder="End Time"
              />
              <ErrorMessage
                name="timeend"
                component="div"
                className="text-danger input-feedback"
              />
            </div>
          </div>
        </div>

        <div className="form-row">
          <div className="col-12">
            <div className="form-group">
              <label htmlFor="note">Note:</label>
              <Field
                name="note"
                as="textarea"
                type="textarea"
                rows={3}
                className="form-control"
              />
              <ErrorMessage
                name="note"
                component="div"
                className="text-danger input-feedback"
              />
            </div>
          </div>
        </div>

        <div className="form-row">
          <div className="col-12">
            <div className="form-group">
              <h5>Categories:</h5>
              <FieldArray
                name="coursecats"
                render={arrayHelpers => (
                  <div>
                    {cats.map((cat, i) => (
                      <div className="form-check" key={`cat_${i}`}>
                        {/* <input
                          type="checkbox"
                          name={`coursecats[${i}]`}
                          id={`coursecats[${i}]`}
                          value={cat.id}
                          onChange={handleCatChange}
                          checked={inputs.coursecats.includes(cat.id)}
                          className="form-check-input"
                        /> */}
                        <input
                          name="coursecats"
                          id={`coursecats[${i}]`}
                          type="checkbox"
                          className="form-check-input"
                          value={cat.id}
                          checked={props.values.coursecats.includes(cat.id)}
                          onChange={e => {
                            if (e.target.checked) {
                              arrayHelpers.push(cat.id);
                            } else {
                              const idx = props.values.coursecats.indexOf(
                                cat.id
                              );
                              arrayHelpers.remove(idx);
                            }
                          }}
                        />
                        <label
                          htmlFor={`coursecats.${i}`}
                          className="form-check-label"
                        >
                          {cat.title}
                        </label>
                      </div>
                    ))}
                  </div>
                )}
              />
            </div>
          </div>
        </div>

        <h3>Course Manual Info (Optional)</h3>

        <div className="form-row">
          <div className="col-12">
            <div className="form-group">
              <label htmlFor="manual_url">Course Manual URL:</label>
              <Field
                name="manual_url"
                className="form-control"
                placeholder="URL to manual here"
              />
              <ErrorMessage
                name="manual_url"
                component="div"
                className="text-danger input-feedback"
              />
            </div>
          </div>
        </div>

        <div className="form-row">
          <div className="col-12">
            <div className="form-group">
              <label htmlFor="manual_label">Course Manual Label:</label>
              <Field
                name="manual_label"
                className="form-control"
                placeholder="Name/label for manual (optional)"
              />
              <ErrorMessage
                name="manual_label"
                component="div"
                className="text-danger input-feedback"
              />
            </div>
          </div>
        </div>

        <div className="form-row">
          <div className="col-12">
            <div className="form-group">
              <label htmlFor="manual_note">Course Manual Note:</label>
              <Field
                name="manual_note"
                as="textarea"
                type="textarea"
                rows={3}
                className="form-control"
              />
              <ErrorMessage
                name="manual_note"
                component="div"
                className="text-danger input-feedback"
              />
            </div>
          </div>
        </div>

        <h3>Online Status</h3>

        <div className="form-row">
          <div className="col-12">
            <div className="form-group">
              <label htmlFor="note">Online Only?</label>
              <Field name="online" as="select" className="form-control">
                <option value="true">True</option>
                <option value={false}>False</option>
              </Field>
              <ErrorMessage
                name="note"
                component="div"
                className="text-danger input-feedback"
              />
            </div>
          </div>
        </div>

        <button type="submit" className="btn btn-primary btn-lg">
          Save
        </button>
      </Form>
    )}
  </Formik>
);
export default AddScheduleForm;
