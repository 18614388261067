import React, { useState, useContext, useEffect } from "react";
import axios from "axios";
// import { navigate } from "@reach/router";
import { Link } from "gatsby";

import MarqueeForm from "components/forms/MarqueeForm";
import { cartContext } from "providers/CartProvider";

const EditMarquee = () => {
  const siteContext = useContext(cartContext);
  const [initialData, setInitialData] = useState({});

  useEffect(() => {
    async function fetchData() {
      try {
        // const token = siteContext.userToken;

        const response = await axios.get(
          `${process.env.GATSBY_API_URL}/marquee`,
          {
            validateStatus(status) {
              return status < 500; // Resolve only if the status code is less than 500
            },
          }
        );

        setInitialData(response.data);
        // console.log("fetchData -> response.data", response.data);
      } catch (e) {
        siteContext.makeToast("Unable to fetch marquee details.", "error");
      }
    }

    fetchData();
  }, [siteContext]);

  const handleSubmit = async values => {
    // e.preventDefault();

    const response = await axios.put(
      `${process.env.GATSBY_API_URL}/admin-tools/update-marquee`,
      values,
      {
        headers: {
          Authorization: `Bearer ${siteContext.userToken}`,
        },
        validateStatus(status) {
          return status < 500; // Resolve only if the status code is less than 500
        },
      }
    );

    if (response && response.status === 200) {
      return siteContext.makeToast(
        "Marquee updated. No need to rebuild the site, these changes are instant.",
        "success"
      );
    }
    const errorMsg = response.data || "There was a problem.";
    return siteContext.makeToast(errorMsg, "error");
  };

  return (
    <div>
      <h2>Admin: Edit Marquee</h2>

      <p className="mt-3">
        <Link to="/admin/dashboard" className="btn btn-success">
          Cancel / Go Back
        </Link>
      </p>
      {initialData && (
        <MarqueeForm submitIt={handleSubmit} initVals={initialData} />
      )}
    </div>
  );
};

export default EditMarquee;
