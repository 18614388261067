import React, { useContext } from "react";
import axios from "axios";
import { Link } from "gatsby";
import { navigate } from "@reach/router";

import UpdatePasswordForm from "components/forms/UpdatePasswordForm";
import { cartContext } from "providers/CartProvider";

const ConfirmReset = ({ id, token }) => {
  const siteContext = useContext(cartContext);

  const handleSubmit = async values => {
    values.token = token;
    values.userID = id;
    try {
      const response = await axios.post(
        `${process.env.GATSBY_API_URL}/users/update-password-reset`,
        values,
        {
          validateStatus(status) {
            return status < 500; // Resolve only if the status code is less than 500
          },
        }
      );

      if (response && response.status === 200) {
        siteContext.makeToast(
          "Password updated. You can now login with it.",
          "success"
        );
        navigate("/admin/dashboard/login");
      } else {
        siteContext.makeToast("Unable to change password.", "error");
      }
    } catch (err) {
      siteContext.makeToast("Server error, password not changed.", "error");
    }
  };

  return (
    <div>
      <h2>Reset Your Password</h2>

      <Link to="/admin/dashboard" className="btn btn-sm btn-info">
        Cancel
      </Link>

      <hr />

      <UpdatePasswordForm submitIt={handleSubmit} />
    </div>
  );
};

export default ConfirmReset;
