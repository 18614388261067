import React from "react";
import { Formik, Field, Form, ErrorMessage } from "formik";

import { loginValidationSchema } from "helpers/validation";

const UserLoginForm = ({ submitIt }) => (
  <Formik
    initialValues={{
      email: "",
      password: "",
    }}
    validationSchema={loginValidationSchema}
    onSubmit={(values, actions) => {
      submitIt(values);
      actions.setSubmitting(false);
    }}
  >
    {props => (
      <Form className="form">
        <div className="form-row">
          <div className="col-12 col-md-6">
            <div className="form-group">
              <label htmlFor="email" className="sr-only">
                Email Address:
              </label>
              <Field
                name="email"
                placeholder="Your Email"
                type="email"
                className={`form-control ${
                  props.touched.email && props.errors.email && "is-invalid"
                }`}
                required
              />
              <ErrorMessage
                name="email"
                component="div"
                className="text-danger input-feedback"
              />
            </div>
          </div>
        </div>

        <div className="form-row">
          <div className="col-12 col-md-6">
            <div className="form-group">
              <label htmlFor="password" className="sr-only">
                Password:
              </label>
              <Field
                name="password"
                placeholder="Your Password"
                type="password"
                className={`form-control ${
                  props.touched.password &&
                  props.errors.password &&
                  "is-invalid"
                }`}
                required
              />
              <ErrorMessage
                name="password"
                component="div"
                className="text-danger input-feedback"
              />
            </div>
          </div>
        </div>

        <button type="submit" className="btn btn-success">
          Submit
        </button>
      </Form>
    )}
  </Formik>
);

export default UserLoginForm;
