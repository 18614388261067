import React, { useEffect, useState, useContext } from "react";
import axios from "axios";
import { navigate } from "@reach/router";
import { Link } from "gatsby";

import UsersDataTable from "components/DataTable/UsersDataTable";
import { isLoggedInAdmin } from "services/auth";
import { cartContext } from "providers/CartProvider";

const AdminSchedules = () => {
  const [usersData, setUsersData] = useState(null);
  // const [errors, setErrors] = useState(null);

  const siteContext = useContext(cartContext);

  useEffect(() => {
    async function fetchData() {
      try {
        const token = siteContext.userToken;
        // console.log("fetchData -> token", token);

        const response = await axios.get(
          `${process.env.GATSBY_API_URL}/admin-tools/users-listing`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        // console.log("Main -> response.data", response.data);
        setUsersData(response.data);
      } catch (e) {
        siteContext.makeToast("You are not authorized.", "error");
      }
    }

    fetchData();
  }, [siteContext]);

  const handleDelete = async id => {
    // console.log("id to delete: ", id);
    if (!window.confirm("Are you sure you want to delete this user?")) {
      return;
    }

    try {
      const token = siteContext.userToken;
      const delResponse = await axios.delete(
        `${process.env.GATSBY_API_URL}/admin-tools/delete-user/${id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      // console.log("AdminSchedules -> scheduleData", scheduleData);
      const updatedUsersData = usersData.filter(x => x.id !== id);
      setUsersData(updatedUsersData);

      // const response = await axios.get(
      //   `${process.env.GATSBY_API_URL}:${process.env.GATSBY_API_PORT}/admin-tools/schedules`,
      //   {
      //     headers: {
      //       Authorization: `Bearer ${token}`,
      //     },
      //   }
      // );

      // // console.log("Main -> response.data", response.data);
      // setScheduleData(response.data);
      siteContext.makeToast("User deleted.", "success");
    } catch (e) {
      siteContext.makeToast("User cannot be deleted.", "error");
    }
  };

  return (
    <div>
      <h2>Admin: Users</h2>

      {usersData && (
        <UsersDataTable data={usersData} handleDelete={handleDelete} />
      )}
    </div>
  );
};

export default AdminSchedules;
