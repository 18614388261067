import React from "react";
import { Formik, Field, Form, ErrorMessage } from "formik";

import { resetPasswordValidationSchema } from "helpers/validation";

const ResetPasswordForm = ({ submitIt }) => (
  <Formik
    initialValues={{
      email: "",
    }}
    validationSchema={resetPasswordValidationSchema}
    onSubmit={(values, actions) => {
      actions.setSubmitting(true);
      submitIt(values, actions);
    }}
  >
    {props => (
      <Form className="form">
        <div className="form-row">
          <div className="col-12 col-md-6">
            <div className="form-group">
              <label htmlFor="email" className="sr-only">
                Enter Your Email:
              </label>
              <Field
                name="email"
                type="email"
                placeholder="Your Email"
                className="form-control"
                required
              />
              <ErrorMessage
                name="email"
                component="div"
                className="text-danger input-feedback"
              />
            </div>
          </div>
        </div>

        <button
          type="submit"
          className="btn btn-success"
          disabled={props.isSubmitting}
        >
          Submit
        </button>
      </Form>
    )}
  </Formik>
);

export default ResetPasswordForm;
