import React, { useState, useContext, useEffect } from "react";
import axios from "axios";
// import { navigate } from "@reach/router";
import { Link } from "gatsby";

import { cartContext } from "providers/CartProvider";
import { isoToDate, formatToMilitaryDate } from "helpers";
import { StyledClassView } from "./styles";

const ViewOtherevent = ({ id }) => {
  const siteContext = useContext(cartContext);
  const [initialData, setInitialData] = useState({});
  const [refresher, setRefresher] = useState("");

  useEffect(() => {
    async function fetchData() {
      try {
        const token = siteContext.userToken;

        const response = await axios.get(
          `${process.env.GATSBY_API_URL}/admin-tools/otherevent/${id}`,
          {
            headers: {
              Authorization: `Bearer ${siteContext.userToken}`,
            },
            validateStatus(status) {
              return status < 500; // Resolve only if the status code is less than 500
            },
          }
        );

        const formattedDate = isoToDate(response.data.date);
        response.data.date = formattedDate;

        if (response.data.datestop) {
          const formattedDateStop = isoToDate(response.data.datestop);
          response.data.datestop = formattedDateStop;
        }

        // console.log("data: ", response.data);
        setInitialData(response.data);
      } catch (e) {
        siteContext.makeToast("Unable to fetch event details.", "error");
      }
    }

    fetchData(id);
  }, [siteContext, id, refresher]);

  return (
    <div>
      <h2 className="mb-4">Admin: View "Other Event" Item</h2>

      <p className="my-3">
        <Link to="/admin/dashboard/otherevents" className="btn btn-success">
          Back To List
        </Link>{" "}
        <Link
          to={`/admin/dashboard/otherevents/edit/${id}`}
          className="btn btn-info"
        >
          Edit This Item
        </Link>
      </p>
      {initialData && initialData.title && (
        <StyledClassView>
          <h4>{initialData.title}</h4>

          <dl className="row mt-3">
            <dt className="col-sm-3">Start Date:</dt>
            <dd className="col-sm-9">
              {formatToMilitaryDate(initialData.date)}
            </dd>

            <dt className="col-sm-3">End Date:</dt>
            <dd className="col-sm-9">
              {initialData.datestop
                ? formatToMilitaryDate(initialData.datestop)
                : "(not set)"}
            </dd>

            <dt className="col-sm-3">Start Time:</dt>
            <dd className="col-sm-9">{initialData.timestart}</dd>

            <dt className="col-sm-3">End Time:</dt>
            <dd className="col-sm-9">{initialData.timeend}</dd>
          </dl>

          {initialData.note && (
            <div>
              <hr />
              <h5>Note:</h5>
              <p>{initialData.note}</p>
            </div>
          )}

          {initialData.url && (
            <div>
              <hr />
              <h5>URL:</h5>
              <p>
                <a href={initialData.url} rel="noreferrer" target="_blank">
                  {initialData.url}
                </a>
              </p>
            </div>
          )}
        </StyledClassView>
      )}
    </div>
  );
};

export default ViewOtherevent;
