import React from "react";
import { Formik, Field, Form, ErrorMessage, FieldArray } from "formik";
import { format } from "date-fns";

import { othereventValidationSchema } from "helpers/validation";

const AddOthereventForm = ({ submitIt, cats, initVals = null }) => (
  // console.log("initVals: ", initVals);

  <Formik
    initialValues={{
      title: initVals ? initVals.title : "",
      date: initVals ? initVals.date : format(new Date(), "yyyy-MM-dd"),
      timestart: initVals ? initVals.timestart : "09:00",
      timeend: initVals ? initVals.timeend : "14:00",
      datestop: initVals && initVals.datestop ? initVals.datestop : "",
      note: initVals && initVals.note ? initVals.note : "",
    }}
    validationSchema={othereventValidationSchema}
    onSubmit={(values, actions) => {
      // alert(JSON.stringify(values, null, 2));
      submitIt(values);
      actions.setSubmitting(false);
    }}
  >
    {props => (
      <Form className="form">
        <div className="form-row">
          <div className="col-12">
            <div className="form-group">
              <label htmlFor="title">Event Name/Title:</label>
              <Field
                name="title"
                placeholder="Title"
                className="form-control"
                required
              />
              <ErrorMessage
                name="title"
                component="div"
                className="text-danger input-feedback"
              />
            </div>
          </div>
        </div>

        <div className="form-row">
          <div className="col-12 col-md-6">
            <div className="form-group">
              <label htmlFor="date">Start Date:</label>
              <Field
                name="date"
                placeholder="Select Date"
                type="date"
                className="form-control"
                required
              />
              <ErrorMessage
                name="date"
                component="div"
                className="text-danger input-feedback"
              />
            </div>
          </div>

          <div className="col-12 col-md-6">
            <div className="form-group">
              <label htmlFor="timestart">Start Time:</label>
              <Field
                name="timestart"
                className="form-control"
                placeholder="Start Time"
                required
              />
              <ErrorMessage
                name="timestart"
                component="div"
                className="text-danger input-feedback"
              />
            </div>
          </div>
        </div>

        <div className="form-row">
          <div className="col-12 col-md-6">
            <div className="form-group">
              <label htmlFor="datestop">End Date:</label>
              <Field
                type="date"
                name="datestop"
                className="form-control"
                placeholder="End Time"
              />
              <ErrorMessage
                name="datestop"
                component="div"
                className="text-danger input-feedback"
              />
            </div>
          </div>
          <div className="col-12 col-md-6">
            <div className="form-group">
              <label htmlFor="timeend">End Time:</label>
              <Field
                name="timeend"
                className="form-control"
                placeholder="End Time"
              />
              <ErrorMessage
                name="timeend"
                component="div"
                className="text-danger input-feedback"
              />
            </div>
          </div>
        </div>

        <div className="form-row">
          <div className="col-12">
            <div className="form-group">
              <label htmlFor="note">Note:</label>
              <Field
                name="note"
                as="textarea"
                type="textarea"
                rows={4}
                className="form-control"
              />
              <ErrorMessage
                name="note"
                component="div"
                className="text-danger input-feedback"
              />
            </div>
          </div>
        </div>

        <div className="form-row">
          <div className="col-12">
            <div className="form-group">
              <label htmlFor="url">Optional URL:</label>
              <Field name="url" placeholder="URL" className="form-control" />
              <ErrorMessage
                name="url"
                component="div"
                className="text-danger input-feedback"
              />
            </div>
          </div>
        </div>

        <button type="submit" className="btn btn-primary btn-lg">
          Save
        </button>
      </Form>
    )}
  </Formik>
);
export default AddOthereventForm;
