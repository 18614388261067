import React, { useState, useMemo } from "react";
import { Link } from "gatsby";
import DataTable from "react-data-table-component";
import memoize from "memoize-one";
import { json2csvAsync } from "json-2-csv";
import format from "date-fns/format";
import parseISO from "date-fns/parseISO";
import { FaCheck } from "react-icons/fa";

import { isoToDate } from "helpers";

// Blatant "inspiration" from https://codepen.io/Jacqueline34/pen/pyVoWr
async function downloadCSV(array) {
  const link = document.createElement("a");

  // format 'users' and 'coursecats' into just IDs here
  const newArray = array.slice().map(row => {
    // row.users = row.users.map(x => x.id);
    // if (row.users.length === 0) {
    //   row.users = "";
    // }

    // row.coursecats = row.coursecats.map(x => x.title).toString();
    if (Array.isArray(row.coursecats)) {
      row.coursecats = row.coursecats
        .map(x => `id:${x.id}|title:${x.title}`)
        .toString();
    }
    if (Array.isArray(row.users)) {
      row.users = row.users.map(x => `id:${x.id}|email:${x.email}`).toString();
    }
    return row;
  });
  let csv = await json2csvAsync(newArray, {
    // expandArrayObjects: true,
    // keys: ["namelast", "namefirst", "email", "schedules"],
  });
  if (csv == null) return;

  const filename = "export.csv";

  if (!csv.match(/^data:text\/csv/i)) {
    csv = `data:text/csv;charset=utf-8,${csv}`;
  }

  link.setAttribute("href", encodeURI(csv));
  link.setAttribute("download", filename);
  link.click();
}

const Export = ({ onExport }) => (
  <div className="ml-4">
    <button
      type="button"
      className="btn btn-info"
      onClick={e => onExport(e.target.value)}
    >
      Export CSV
    </button>
  </div>
);

const catsCell = row => (
  <div>
    {Array.isArray(row.coursecats) && row.coursecats.length > 0
      ? row.coursecats.map((s, i) => <div key={i}>{s.title}</div>)
      : row.coursecats.title}
    {/* {row.coursecats &&
      Array.isArray(row.coursecats) &&
      row.coursecats.length > 0 &&
      row.coursecats.map((s, i) => <div key={i}>{s.title}</div>)} */}
  </div>
);

const enrolledCell = row => (
  <div>
    {row.users &&
      Array.isArray(row.orders) &&
      row.orders.reduce((acc, curr) => acc + Number(curr.qty), 0)}
  </div>
);

const delCell = memoize((row, handleDelete) => (
  <button
    type="button"
    className="btn btn-sm btn-danger"
    onClick={() => handleDelete(row.id)}
  >
    Delete
  </button>
));

const manualCell = memoize(row => (
  <div>
    {row.manual_url ? (
      <a href={row.manual_url} target="_blank" rel="noreferrer">
        View
      </a>
    ) : (
      "no"
    )}
  </div>
));

const viewCell = row => (
  <Link
    to={`/admin/dashboard/schedules/view/${row.id}`}
    className="btn btn-sm btn-info"
  >
    View
  </Link>
);

const ShowPastComponent = ({ handleChange, val, className }) => (
  <div className={className}>
    <div className="form-check">
      <input
        className="form-check-input"
        name="showAll"
        type="checkbox"
        checked={val}
        onChange={() => handleChange(!val)}
      />
      <label className="form-check-label font-weight-bold" htmlFor="showAll">
        Show Past Items?
      </label>
    </div>
  </div>
);

const FilterComponent = ({ filterText, onFilter, onClear }) => (
  <div className="input-group" style={{ width: "300px" }}>
    <input
      id="search"
      type="text"
      placeholder="Filter By Class Name"
      value={filterText}
      onChange={onFilter}
      className="form-control"
    />
    <div className="input-group-append">
      <button type="button" onClick={onClear} className="btn btn-secondary">
        X
      </button>
    </div>
  </div>
);

const columns = memoize(handleDelete => [
  {
    name: "Date",
    selector: "date",
    sortable: true,
    cell: row => format(parseISO(row.date), "dd LLL yyyy"),
    width: "120px",
  },
  {
    name: "Title",
    selector: "title",
    sortable: true,
    grow: 0.8,
    wrap: true,
  },
  {
    name: "End Date",
    selector: "datestop",
    sortable: true,
    width: "120px",
    cell: row =>
      row.datestop ? format(parseISO(row.datestop), "dd LLL yyyy") : "not set",
    center: true,
  },
  {
    name: "Total Seats",
    selector: "totalseats",
    sortable: false,
    width: "100px",
    center: true,
  },
  {
    name: "Enrolled",
    selector: "totalseats",
    sortable: false,
    cell: row => enrolledCell(row),
    width: "70px",
    center: true,
  },
  {
    name: "Price",
    selector: "price",
    sortable: false,
    width: "70px",
    center: true,
  },
  {
    name: "Online",
    selector: "online",
    sortable: true,
    width: "40px",
    center: true,
    cell: row => (row.online === true ? <FaCheck /> : ""),
  },
  {
    name: "Categories",
    selector: "coursecats",
    sortable: true,
    cell: row => catsCell(row),
    width: "180px",
    wrap: true,
  },
  {
    name: "Manual?",
    selector: "manual_url",
    sortable: true,
    cell: row => manualCell(row),
    width: "60px",
  },
  {
    name: "View/Edit",
    button: true,
    cell: row => viewCell(row),
    width: "70px",
  },
  {
    name: "Delete",
    button: true,
    cell: row => delCell(row, handleDelete),
    width: "70px",
  },
]);

const SchedulesDataTable = ({ data, handleDelete }) => {
  const [filterText, setFilterText] = useState("");
  const [showPast, setShowPast] = useState(false);
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);

  // const actionsMemo = useMemo(
  //   () => <Export onExport={() => downloadCSV(data)} />,
  //   [data]
  // );

  let filteredItems = [];

  filteredItems =
    data &&
    data.filter(
      item =>
        item.title &&
        item.title.toLowerCase().includes(filterText.toLowerCase())
    );

  if (!showPast && filteredItems) {
    filteredItems = filteredItems.filter(x => new Date(x.date) >= new Date());
  }

  const subHeaderComponentMemo = useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText("");
      }
    };

    return (
      <div className="d-flex flex-row align-items-center justify-content-between w-100">
        <ShowPastComponent
          handleChange={setShowPast}
          val={showPast}
          className="mr-auto"
        />

        <FilterComponent
          onFilter={e => setFilterText(e.target.value)}
          onClear={handleClear}
          filterText={filterText}
        />
        <Export onExport={() => downloadCSV(data)} />
      </div>
    );
  }, [filterText, resetPaginationToggle, data, showPast]);

  return (
    data && (
      <DataTable
        title="Schedules"
        columns={columns(handleDelete)}
        data={filteredItems}
        pagination
        paginationResetDefaultPage={resetPaginationToggle} // a hook to reset pagination to page 1
        subHeader
        subHeaderComponent={subHeaderComponentMemo}
        responsive
        // noTableHead
        noHeader
        // actions={actionsMemo}
      />
    )
  );
};

export default SchedulesDataTable;
