import React, { useState, useContext, useEffect } from "react";
import axios from "axios";
import { navigate } from "@reach/router";
import { Link } from "gatsby";

import { isoToDate } from "helpers";
import AdminScheduleForm from "components/forms/AdminScheduleForm";
import { cartContext } from "providers/CartProvider";

const EditSchedule = ({ id }) => {
  const siteContext = useContext(cartContext);
  const [cats, setCats] = useState([]);
  const [manuals, setManuals] = useState([]);
  const [initialData, setInitialData] = useState({});

  useEffect(() => {
    // async function fetchManuals() {
    //   try {
    //     const response = await axios.get(
    //       `${process.env.GATSBY_API_URL}/courses/coursemanuals`
    //     );
    //     setManuals(response.data);
    //   } catch (e) {
    //     siteContext.makeToast("Error fetching manuals.", "error");
    //   }
    // }

    async function fetchCats() {
      try {
        const response = await axios.get(
          `${process.env.GATSBY_API_URL}/courses/coursecats`
        );
        setCats(response.data);
      } catch (e) {
        siteContext.makeToast("Error fetching categories.", "error");
      }
    }

    async function fetchData() {
      try {
        const token = siteContext.userToken;

        const response = await axios.get(
          `${process.env.GATSBY_API_URL}/courses/schedule/${id}`,
          {
            validateStatus(status) {
              return status < 500; // Resolve only if the status code is less than 500
            },
          }
        );

        // adjust data for our forms
        const coursecats = response.data.coursecats.map(x => x.id);
        response.data.coursecats = coursecats;

        const formattedDate = isoToDate(response.data.date);
        response.data.date = formattedDate;

        if (response.data.datestop) {
          // test
          const formattedDateStop = isoToDate(response.data.datestop);
          response.data.datestop = formattedDateStop;
        }

        setInitialData(response.data);
        // console.log("fetchData -> response.data", response.data);
      } catch (e) {
        siteContext.makeToast("Unable to fetch schedule details.", "error");
      }
    }

    // fetchManuals();
    fetchCats();
    fetchData(id);
  }, [siteContext, id]);

  const handleSubmit = async values => {
    // e.preventDefault();

    const response = await axios.put(
      `${process.env.GATSBY_API_URL}/admin-tools/schedule-update/${id}`,
      values,
      {
        headers: {
          Authorization: `Bearer ${siteContext.userToken}`,
        },
        validateStatus(status) {
          return status < 500; // Resolve only if the status code is less than 500
        },
      }
    );

    if (response && response.status === 200) {
      siteContext.makeToast(
        "Schedule updated. Don't forget to rebuild the site after making all your changes!",
        "success"
      );
      return navigate(`/admin/dashboard/schedules/view/${id}`);
    }
    const errorMsg = response.data || "There was a problem.";
    return siteContext.makeToast(errorMsg, "error");
  };

  return (
    <div>
      <h2>Admin: Edit Schedule</h2>

      <p className="mt-3">
        <Link to="/admin/dashboard/schedules" className="btn btn-success">
          Cancel
        </Link>
      </p>
      {initialData && initialData.title && cats && (
        <AdminScheduleForm
          submitIt={handleSubmit}
          cats={cats}
          initVals={initialData}
        />
      )}
    </div>
  );
};

export default EditSchedule;
