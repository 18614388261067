import React, { useEffect, useState, useContext } from "react";
import Papa from "papaparse";
import { Link } from "gatsby";
import axios from "axios";

import SchedulesDataTable from "components/DataTable/SchedulesDataTable";
import { isoToDate } from "helpers";
import { cartContext } from "providers/CartProvider";
// import { isLoggedInAdmin } from "services/auth";
import { StyledSplitContainer } from "./styles";

const AdminSchedules = () => {
  const [scheduleData, setScheduleData] = useState(null);
  const [uploadData, setUploadData] = useState(null);
  const [uploadResponse, setUploadResponse] = useState(null);

  const siteContext = useContext(cartContext);

  useEffect(() => {
    async function fetchData() {
      try {
        const token = siteContext.userToken;
        // console.log("fetchData -> token", token);

        const response = await axios.get(
          `${process.env.GATSBY_API_URL}/admin-tools/schedules`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
            validateStatus(status) {
              return status < 500; // Resolve only if the status code is less than 500
            },
          }
        );

        // console.log("Main -> response.data", response.data);
        setScheduleData(response.data);
      } catch (e) {
        siteContext.makeToast("Unable to fetch schedules.", "error");
      }
    }

    fetchData();
  }, [siteContext, uploadResponse]);

  const handleFileInputChange = async e => {
    if (e.target.files.length > 0) {
      // console.log("e.target.files[0]: ", e.target.files[0]);
      setUploadData(e.target.files[0]);
    }
  };

  const handleSubmit = e => {
    e.persist();
    e.preventDefault();

    Papa.parse(uploadData, {
      async complete(results, file) {
        try {
          const token = siteContext.userToken;

          const response = await axios.post(
            `${process.env.GATSBY_API_URL}/admin-tools/batch-add-schedules`,
            {
              jsondata: results.data,
            },
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );
          if (response.status === 200) {
            siteContext.makeToast("File import was successful.", "success");
            setUploadResponse(response);
            e.target.reset();
            // document.getElementById("create-course-form").reset();
          }
        } catch (err) {
          // eslint-disable-next-line
          console.log(err);
          siteContext.makeToast("File import was not successful.", "error");
        }
      },
      header: true,
      dynamicTyping: true,
    });
  };

  const handleDelete = async id => {
    // console.log("id to delete: ", id);
    if (!window.confirm("Are you sure you want to delete this item?")) {
      return;
    }

    try {
      const token = siteContext.userToken;
      const delResponse = await axios.delete(
        `${process.env.GATSBY_API_URL}/admin-tools/schedules/delete/${id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      // console.log("AdminSchedules -> scheduleData", scheduleData);
      const updatedScheduleData = scheduleData.filter(x => x.id !== id);
      setScheduleData(updatedScheduleData);

      // const response = await axios.get(
      //   `${process.env.GATSBY_API_URL}:${process.env.GATSBY_API_PORT}/admin-tools/schedules`,
      //   {
      //     headers: {
      //       Authorization: `Bearer ${token}`,
      //     },
      //   }
      // );

      // // console.log("Main -> response.data", response.data);
      // setScheduleData(response.data);
      siteContext.makeToast("Scheduled class deleted.", "success");
    } catch (e) {
      siteContext.makeToast(
        "Classes with students enrolled cannot be deleted.",
        "error"
      );
    }
  };

  return (
    <div>
      <h2>Admin: Schedules</h2>

      <p className="font-italic text-danger">
        *Reminder: Once you are done adding/changing schedule items, be sure to
        click the "trigger site rebuild" button above to publish your updates.
      </p>

      <StyledSplitContainer>
        <div className="top-box">
          <div className="inner-item">
            <Link
              to="/admin/dashboard/schedules/add"
              className="btn btn-primary"
            >
              Add New Schedule Item
            </Link>
          </div>

          <div className="inner-item">
            <h5>Import from CSV</h5>
            <form className="form" onSubmit={handleSubmit} id="batchform">
              <label className="sr-only" htmlFor="filebox">
                File:{" "}
              </label>
              <input
                className="form-control-file"
                type="file"
                id="filebox"
                onChange={handleFileInputChange}
              />
              <button type="submit" className="btn btn-sm btn-primary mt-2">
                Upload
              </button>
            </form>
          </div>

          <div className="inner-item">
            <a
              href="https://docs.google.com/spreadsheets/d/1V3QlJnhuGvZdTzbIiiCcB7OevD28GA9P-0qBVd5bQtk/edit?usp=sharing"
              className="btn btn-primary btn-sm"
              target="_blank"
              rel="noopener noreferrer"
            >
              Google Schedules Template
            </a>
            <p
              style={{
                fontSize: "12px",
                margin: "6px 0 0 0",
                textAlign: "center",
              }}
            >
              Edit the google sheets document and save as ".CSV", then import it
              here.
            </p>
          </div>
        </div>

        <div className="lower-box">
          <div>
            <SchedulesDataTable
              data={scheduleData}
              handleDelete={handleDelete}
            />
          </div>
        </div>
      </StyledSplitContainer>
    </div>
  );
};

export default AdminSchedules;
