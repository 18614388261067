import React from "react";
import { Formik, Field, Form, ErrorMessage, FieldArray } from "formik";

// import { marqueeValidationSchema } from "helpers/validation";

const MarqueeForm = ({ submitIt, initVals }) => {
  if (!initVals.enabled) {
    return null;
  }

  if (initVals.enabled)
    return (
      <Formik
        initialValues={{
          enabled: initVals.enabled,
          items: initVals.items || [],
        }}
        // validationSchema={marqueeValidationSchema}
        onSubmit={(values, actions) => {
          // alert(JSON.stringify(values, null, 2));
          submitIt(values);
          actions.setSubmitting(false);
        }}
        render={({ values }) => (
          <Form className="form">
            <div className="form-row">
              <div className="col-12">
                <div className="form-group">
                  <label htmlFor="enabled">
                    <Field
                      name="enabled"
                      type="checkbox"
                      // className="form-control"
                    />{" "}
                    Enable Marquee?
                  </label>
                  <ErrorMessage
                    name="enabled"
                    component="div"
                    className="text-danger input-feedback"
                  />
                </div>
              </div>
            </div>

            <FieldArray
              name="items"
              render={arrayHelpers => (
                <div>
                  <h4>Marquee Items</h4>

                  {values.items &&
                    values.items.length > 0 &&
                    values.items.map((item, i) => (
                      <div className="form-row border-bottom pb-4 mb-4" key={i}>
                        <div className="col-12">
                          <div className="form-group">
                            <label htmlFor={`items[${i}].label`}>
                              Marquee Text:
                            </label>
                            <Field
                              name={`items[${i}].label`}
                              placeholder="Marquee text"
                              className="form-control"
                              required
                            />
                            <ErrorMessage
                              name={`items[${i}].label`}
                              component="div"
                              className="text-danger input-feedback"
                            />
                          </div>
                        </div>

                        <div className="col-12">
                          <div className="form-group">
                            <label htmlFor={`items[${i}].url`}>URL:</label>
                            <Field
                              name={`items[${i}].url`}
                              placeholder="button link"
                              className="form-control"
                            />
                            <ErrorMessage
                              name={`items[${i}].url`}
                              component="div"
                              className="text-danger input-feedback"
                            />
                          </div>
                        </div>

                        <button
                          type="button"
                          className="btn btn-danger btn-sm"
                          onClick={() => arrayHelpers.remove(i)}
                        >
                          remove
                        </button>
                      </div>
                    ))}

                  <button
                    type="button"
                    className="btn btn-info mb-4"
                    onClick={() => arrayHelpers.push({ label: "", url: "" })}
                  >
                    Add New Item
                  </button>
                </div>
              )}
            />
            <hr />

            <button type="submit" className="btn btn-success btn-lg">
              Save All Items
            </button>
          </Form>
        )}
      />
    );
};
export default MarqueeForm;
