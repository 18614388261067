import React, { useEffect, useState, useContext } from "react";
import axios from "axios";

import OrdersDataTable from "components/DataTable/OrdersDataTable";
import { cartContext } from "providers/CartProvider";

const AdminOrders = () => {
  const [ordersData, setOrdersData] = useState(null);

  const siteContext = useContext(cartContext);

  useEffect(() => {
    async function fetchData() {
      try {
        const token = siteContext.userToken;

        const response = await axios.get(
          `${process.env.GATSBY_API_URL}/admin-tools/orders`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
            validateStatus(status) {
              return status < 500; // Resolve only if the status code is less than 500
            },
          }
        );

        const uniqueOrders = response.data.reduce((acc, cur) => {
          const match = acc.find(x => x.ordernum === cur.ordernum);
          if (!match) {
            acc.push(cur);
          }
          return acc;
        }, []);

        setOrdersData(uniqueOrders);
      } catch (e) {
        siteContext.makeToast("Unable to fetch orders.", "error");
      }
    }

    fetchData();
  }, [siteContext]);

  const handleDelete = async ordernum => {
    if (
      !window.confirm("Attention: Are you sure you want to delete this order?")
    ) {
      return;
    }

    try {
      const token = siteContext.userToken;
      const delResponse = await axios.delete(
        `${process.env.GATSBY_API_URL}/admin-tools/order-all/${ordernum}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      // console.log("AdminSchedules -> scheduleData", scheduleData);
      const updatedOrdersData = ordersData.filter(x => x.ordernum !== ordernum);
      setOrdersData(updatedOrdersData);
      siteContext.makeToast("Order deleted.", "success");
    } catch (e) {
      siteContext.makeToast("Order could not be deleted.", "error");
    }
  };

  return (
    <div>
      <h2>Admin: Orders</h2>

      <OrdersDataTable data={ordersData} handleDelete={handleDelete} />
    </div>
  );
};

export default AdminOrders;
