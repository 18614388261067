import styled from "styled-components";

export const StyledSplitContainer = styled.div`
  /* display: flex;
  flex-direction: column;
  align-items: flex-start; */

  @media (min-width: 992px) {
    /* flex-direction: row; */
  }

  div.top-box {
    display: flex;
    flex-direction: column;
    width: 100%;
    background: rgba(200 200 200 / 0.3);
    margin: 0 0 2rem 0;
    padding: 1rem;

    @media (min-width: 992px) {
      flex-direction: row;
      align-items: stretch;
      justify-content: space-between;
    }

    h5 {
      font-size: 1.25rem;
      margin: 0 0 1rem;
      padding: 0;
    }

    div.inner-item {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin: 0 0 2rem;

      &:last-of-type {
        border-right: none;
      }

      @media (min-width: 992px) {
        border-right: 1px solid #ccc;
        margin-bottom: 0;
        width: calc(33% - 10px);
      }

      form {
        display: flex;
        flex-direction: column;
        align-items: center;

        input[type="file"] {
          background: rgba(250, 250, 250, 0.8);
        }
      }
    }
  }

  /* div.split-right {
    max-width: 100%;
  } */
`;

export const StyledClassView = styled.div`
  margin: 0 0 2rem;
  padding: 0;

  h4 {
    border: none;
    margin: 2rem 0 0;

    &:after {
      content: none;
    }
  }

  p {
    font-size: 14px;
    line-height: 1.33;
    margin: 10px 0 0;
    padding: 0;
  }
`;

export const StyledClassUser = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  margin: 0 0 1rem;
  padding: 1rem;
  border: 1px solid #ccc;

  div.details {
    width: 190px;
    margin-bottom: 30px;
    align-self: flex-start;

    @media (min-width: 768px) {
      margin-bottom: 0;
    }
  }

  div.attendees {
    width: 290px;
    align-self: flex-start;
  }

  p {
    font-size: 13px;
    line-height: 1.45;
    margin: 0;
  }
`;
