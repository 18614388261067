import React, { useContext, useEffect, useState } from "react";
import { Router } from "@reach/router";
import { Container } from "react-bootstrap";
import { Link } from "gatsby";
import axios from "axios";

import { isLoggedInAdmin } from "services/auth";
import { cartContext } from "providers/CartProvider";
import Layout from "components/Layout";
import SEO from "components/Seo";

import Main from "components/dashboard/admin/Main";
import EditMarquee from "components/dashboard/admin/UpdateMarquee";
import Orders from "components/dashboard/admin/Orders";
import ViewOrder from "components/dashboard/admin/ViewOrder";
import Otherevents from "components/dashboard/admin/Otherevents";
import ViewOtherevent from "components/dashboard/admin/ViewOtherevent";
import AddOtherevent from "components/dashboard/admin/AddOtherevent";
import EditOtherevent from "components/dashboard/admin/EditOtherevent";
import Schedules from "components/dashboard/admin/Schedules";
import AddSchedule from "components/dashboard/admin/AddSchedule";
import EditSchedule from "components/dashboard/admin/EditSchedule";
import ViewSchedule from "components/dashboard/admin/ViewSchedule";
import UpdatePassword from "components/dashboard/admin/UpdatePassword";
import ResetPassword from "components/dashboard/admin/ResetPassword";
import ConfirmReset from "components/dashboard/admin/ConfirmReset";
import Users from "components/dashboard/admin/Users";
import EditUser from "components/dashboard/admin/EditUser";
import Login from "components/dashboard/admin/Login";
import PrivateAdminRoute from "components/PrivateAdminRoute";

const DashboardPage = () => {
  const siteContext = useContext(cartContext);

  const [isAuth, setIsAuth] = useState(false);
  const [isBuild, setIsBuild] = useState(false);

  useEffect(() => {
    if (isLoggedInAdmin(siteContext)) {
      setIsAuth(true);
    } else {
      setIsAuth(false);
    }
  }, [siteContext]);

  useEffect(() => {
    if (isBuild) {
      // start timer
      setIsBuild(false);
    }
  }, [isBuild]);

  const handleRebuildClick = async () => {
    try {
      const token = siteContext.getCookieToken();

      const response = await axios.post(
        `${process.env.GATSBY_API_URL}/admin-tools/rebuild-site`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.status === 200) {
        return siteContext.makeToast(
          "Rebuild started! The live site should be updated in 2-4 minutes.",
          "info"
        );
      }

      return siteContext.makeToast(
        "Rebuild could not be started. Try again in a moment.",
        "error"
      );
    } catch (e) {
      siteContext.makeToast(
        "Rebuild could not be started. Try again in a moment.",
        "error"
      );
    }
  };

  const DashboardNavLink = ({ to, children }) => (
    <li className="nav-item">
      <Link
        className="mr-1 nav-link"
        activeClassName="active"
        to={to}
        partiallyActive={to !== "/admin/dashboard"}
      >
        {children}
      </Link>
    </li>
  );

  return (
    <Layout>
      <SEO title="Dashboard" />
      <section>
        <Container>
          <div className="d-flex flex-row justify-content-end">
            {isAuth && (
              <>
                <button
                  type="button"
                  className="nav-link btn btn-success"
                  onClick={handleRebuildClick}
                  disabled={isBuild}
                >
                  Trigger Site Rebuild
                </button>
              </>
            )}
          </div>
          <ul className="nav nav-tabs mb-4">
            {isAuth && (
              <>
                <DashboardNavLink to="/admin/dashboard">
                  Dashboard
                </DashboardNavLink>

                <DashboardNavLink to="/admin/dashboard/users">
                  Users
                </DashboardNavLink>

                <DashboardNavLink to="/admin/dashboard/schedules">
                  Schedules
                </DashboardNavLink>

                <DashboardNavLink to="/admin/dashboard/otherevents">
                  Other Events
                </DashboardNavLink>

                <DashboardNavLink to="/admin/dashboard/orders">
                  Orders
                </DashboardNavLink>

                <DashboardNavLink to="/admin/dashboard/update-marquee">
                  Update Marquee
                </DashboardNavLink>
              </>
            )}
          </ul>

          <div>
            <Router basepath="/admin/dashboard">
              <PrivateAdminRoute path="/" Component={Main} />
              <PrivateAdminRoute path="/orders" Component={Orders} />
              <PrivateAdminRoute
                path="/orders/view/:ordernum"
                Component={ViewOrder}
              />

              <PrivateAdminRoute
                path="/update-marquee"
                Component={EditMarquee}
              />

              <PrivateAdminRoute path="/otherevents" Component={Otherevents} />
              <PrivateAdminRoute
                path="/otherevents/view/:id"
                Component={ViewOtherevent}
              />
              <PrivateAdminRoute
                path="/otherevents/add"
                Component={AddOtherevent}
              />
              <PrivateAdminRoute
                path="/otherevents/edit/:id"
                Component={EditOtherevent}
              />

              <PrivateAdminRoute path="/schedules" Component={Schedules} />

              <PrivateAdminRoute
                path="/schedules/edit/:id"
                Component={EditSchedule}
              />
              <PrivateAdminRoute
                path="/schedules/view/:id"
                Component={ViewSchedule}
              />
              <PrivateAdminRoute
                path="/schedules/add"
                Component={AddSchedule}
              />
              <PrivateAdminRoute
                path="/update-password"
                Component={UpdatePassword}
              />
              <PrivateAdminRoute path="/users/edit/:id" Component={EditUser} />
              <PrivateAdminRoute path="/users" Component={Users} />
              <Login path="/login" />
              <ResetPassword path="/reset-password" />
              <ConfirmReset path="/confirm-reset/:id/:token" />
            </Router>
          </div>
        </Container>
      </section>
    </Layout>
  );
};

export default DashboardPage;
