import React, { useState, useContext, useEffect } from "react";
import axios from "axios";
import { navigate } from "@reach/router";
import { Link } from "gatsby";

import { cartContext } from "providers/CartProvider";
import { isoToDate, formatToMilitaryDate } from "helpers";
import { StyledClassUser, StyledClassView } from "./styles";

const ViewSchedule = ({ id }) => {
  const siteContext = useContext(cartContext);
  const [initialData, setInitialData] = useState({});
  const [refresher, setRefresher] = useState("");

  const handleSendManualEmail = async values => {
    try {
      const token = siteContext.userToken;

      const response = await axios.post(
        `${process.env.GATSBY_API_URL}/admin-tools/resend-manual`,
        values,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          validateStatus(status) {
            return status < 500; // Resolve only if the status code is less than 500
          },
        }
      );

      if (response && response.status === 200) {
        siteContext.makeToast("Email with manual link sent to user.");
      } else {
        siteContext.makeToast("Email was not sent.");
      }
    } catch (e) {
      return siteContext.makeToast(
        "Problem sending email. Wait a moment and try again, or contact Daniel.",
        "error"
      );
    }
  };

  useEffect(() => {
    async function fetchData() {
      try {
        const token = siteContext.userToken;

        const response = await axios.get(
          `${process.env.GATSBY_API_URL}/admin-tools/schedule/${id}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
            validateStatus(status) {
              return status < 500; // Resolve only if the status code is less than 500
            },
          }
        );

        const formattedDate = isoToDate(response.data.date);
        response.data.date = formattedDate;

        if (response.data.datestop) {
          const formattedDateStop = isoToDate(response.data.datestop);
          response.data.datestop = formattedDateStop;
        }

        // console.log("data: ", response.data);
        setInitialData(response.data);
      } catch (e) {
        siteContext.makeToast("Unable to fetch schedule details.", "error");
      }
    }

    fetchData(id);
  }, [siteContext, id, refresher]);

  return (
    <div>
      <h2 className="mb-4">Admin: View Schedule Item</h2>

      <p className="my-3">
        <Link to="/admin/dashboard/schedules" className="btn btn-success">
          Back To List
        </Link>{" "}
        <Link
          to={`/admin/dashboard/schedules/edit/${id}`}
          className="btn btn-info"
        >
          Edit This Item
        </Link>
      </p>
      {initialData && initialData.title && (
        <StyledClassView>
          <h4>{initialData.title}</h4>

          <dl className="row mt-3">
            <dt className="col-sm-3 text-truncate">Categories:</dt>
            <dd className="col-sm-9">
              {initialData.coursecats.map(x => x.title).join(", ")}
            </dd>

            <dt className="col-sm-3">Start Date:</dt>
            <dd className="col-sm-9">
              {formatToMilitaryDate(initialData.date)}
            </dd>

            <dt className="col-sm-3">End Date:</dt>
            <dd className="col-sm-9">
              {initialData.datestop
                ? formatToMilitaryDate(initialData.datestop)
                : "(not set)"}
            </dd>

            <dt className="col-sm-3">Start Time:</dt>
            <dd className="col-sm-9">{initialData.timestart}</dd>

            <dt className="col-sm-3">End Time:</dt>
            <dd className="col-sm-9">{initialData.timeend}</dd>

            <dt className="col-sm-3 text-truncate">Price</dt>
            <dd className="col-sm-9">${initialData.price}</dd>

            <dt className="col-sm-3 text-truncate">Total Seats:</dt>
            <dd className="col-sm-9">
              {initialData.totalseats || "(not set)"}
            </dd>

            <dt className="col-sm-3 text-truncate">Total Enrolled:</dt>
            <dd className="col-sm-9">
              {initialData.orders.reduce((acc, cur) => acc + cur.qty, 0)}
            </dd>
          </dl>

          {initialData.note && (
            <div>
              <hr />
              <h5>Note:</h5>
              <p>{initialData.note}</p>
            </div>
          )}

          {initialData.manual_url && (
            <div>
              <hr />
              <h5>Manual:</h5>
              <p>
                URL:{" "}
                <a
                  href={initialData.manual_url}
                  target="_blank"
                  rel="noreferrer"
                >
                  {initialData.manual_url}
                </a>
              </p>
              {initialData.manual_label && (
                <p>Label: {initialData.manual_label}</p>
              )}
              {initialData.manual_note && (
                <p>Note: {initialData.manual_note}</p>
              )}
            </div>
          )}

          <hr />

          <h5>Purchases:</h5>
          {initialData.users.length > 0 ? (
            initialData.users.map((user, i) => {
              const userOrders = initialData.orders.filter(
                o => o.user_id === user.id
              );

              const seatCount = userOrders.reduce((tot, cur) => {
                const newTot = +tot + +cur.qty;
                return newTot;
              }, 0);

              return (
                <StyledClassUser key={i}>
                  <p>
                    {user.namelast}, {user.namefirst} <br />
                    Email: <a href={`mailto:${user.email}`}>
                      {user.email}
                    </a>{" "}
                    <br /># Orders: {userOrders.length} <br />
                    <br />
                    Total Seats Purchased: {seatCount} <br />
                    <br />
                    {initialData.manual_url && (
                      <button
                        type="button"
                        className="btn btn-info btn-sm"
                        onClick={() => {
                          const data = {
                            email: user.email,
                            id: initialData.id,
                            name: `${user.namefirst} ${user.namelast}`,
                          };
                          handleSendManualEmail(data);
                        }}
                      >
                        send manual
                      </button>
                    )}
                  </p>

                  <div>
                    <p>Order Invoice #(s):</p>

                    <div className="list-group">
                      {userOrders.map((uo, z) => (
                        <li
                          key={z}
                          className="list-group-item list-group-item-action text-center"
                        >
                          <Link
                            to={`/admin/dashboard/orders/view/${uo.ordernum}`}
                          >
                            {uo.ordernum}
                          </Link>
                        </li>
                      ))}
                    </div>
                  </div>

                  {/* <button
                  type="button"
                  className="btn btn-danger btn-sm"
                  onClick={() => handleRemoveUser(id, user.id)}
                >
                  remove
                </button> */}
                </StyledClassUser>
              );
            })
          ) : (
            <p>No users currently enrolled here.</p>
          )}
        </StyledClassView>
      )}
    </div>
  );
};

export default ViewSchedule;
