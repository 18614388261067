// import ...
import React, { useContext, useEffect } from "react";
import { navigate } from "gatsby";
import { isLoggedInAdmin, isBrowser } from "services/auth";
import { cartContext } from "providers/CartProvider";

const PrivateRoute = ({ Component, location, ...rest }) => {
  const siteContext = useContext(cartContext);
  // const token = siteContext.userToken;

  useEffect(() => {
    function toastIt() {
      siteContext.makeToast(
        "You must be logged in for that.",
        "error",
        "wrongpage"
      );
    }

    if (
      !isLoggedInAdmin(siteContext) &&
      location.pathname !== `/admin/dashboard/login`
    ) {
      toastIt();
      siteContext.removeToken();
      navigate("/admin/dashboard/login");
    }
  }, [siteContext, location.pathname]);

  if (!isBrowser()) {
    return null;
  }

  if (
    !isLoggedInAdmin(siteContext) &&
    location.pathname !== `/admin/dashboard/login`
  ) {
    // siteContext.makeToast("You must be logged in for that.", "error");
    // siteContext.removeToken();
    // navigate("/admin/dashboard/login");
    return null;
  }
  return <Component location={location} {...rest} />;
};
export default PrivateRoute;
