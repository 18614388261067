import React, { useEffect, useState, useContext } from "react";
import axios from "axios";
import { Link } from "gatsby";
import { FaArrowDown, FaUser, FaPencilAlt } from "react-icons/fa";

import { handleLogout } from "services/auth";
import { cartContext } from "providers/CartProvider";

const nowMonth = new Date().getMonth();

const Main = props => {
  const [userData, setUserData] = useState(null);
  const [calMonth, setCalMonth] = useState(nowMonth);
  const [errors, setErrors] = useState(null);
  // console.log("props: ", props);

  const siteContext = useContext(cartContext);

  useEffect(() => {
    async function fetchData() {
      try {
        setErrors(null);
        const token = siteContext.userToken;
        // console.log("fetchData -> token", token);

        const response = await axios.get(
          `${process.env.GATSBY_API_URL}/users/profile`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        // console.log("Main -> response.data", response.data.data);
        setUserData(response.data.data);
      } catch (e) {
        setErrors("You are not authorized.");
      }
    }

    fetchData();
  }, [siteContext]);

  return (
    <div>
      <h2>Admin Dashboard</h2>
      {errors && <p>{errors}</p>}

      {userData && (
        <div className="user-container">
          <div className="user-details">
            <p>Welcome back, {userData.namefirst}.</p>
          </div>

          <hr />

          <div>
            <h5>Most Recent Build Status:</h5>
            <img
              src="https://api.netlify.com/api/v1/badges/1c7e1be6-d249-4975-975f-57d7d135d2e8/deploy-status"
              alt="build-status"
            />
          </div>

          <hr />

          <div>
            {/* <p>
              <b>PDF Calendar:</b>

              <select
                value={calMonth}
                onChange={e => setCalMonth(e.target.value)}
                className="form-control ml-2"
                style={{ width: "100px", display: "inline-block" }}
              >
                <option value={0}>Jan</option>
                <option value={1}>Feb</option>
                <option value={2}>Mar</option>
                <option value={3}>Apr</option>
                <option value={4}>May</option>
                <option value={5}>Jun</option>
                <option value={6}>Jul</option>
                <option value={7}>Aug</option>
                <option value={8}>Sep</option>
                <option value={9}>Oct</option>
                <option value={10}>Nov</option>
                <option value={11}>Dec</option>
              </select>

              <a
                className="btn btn-sm btn-success ml-3 d-inline-flex flex-row align-items-center"
                download
                href={`${process.env.GATSBY_API_URL}/calendars/create-calendar/${calMonth}`}
              >
                <FaArrowDown className="mr-1" /> Download Calendar
              </a>
            </p> */}

            <p>
              <a
                className="btn btn-primary"
                rel="noopener noreferrer"
                target="_blank"
                href="https://www.ddsta.com/calendars/"
              >
                DDSTA Calendar Page
              </a>
            </p>
          </div>

          <hr />

          <h4>Actions:</h4>
          <ul style={{ listStyle: "none", padding: "0 0 0 0" }}>
            <li>
              <Link
                to="/admin/dashboard/update-marquee"
                className="btn btn-info"
              >
                Update Homepage Marquee
              </Link>
            </li>
            <li>
              <Link to="/admin/dashboard/schedules" className="btn btn-info">
                View Class Schedules
              </Link>
            </li>
            <li>
              <Link to="/admin/dashboard/otherevents" className="btn btn-info">
                View Other Events
              </Link>
            </li>
            <li>
              <Link to="/admin/dashboard/users" className="btn btn-info">
                View Users
              </Link>
            </li>
            <li>
              <Link to="/admin/dashboard/orders" className="btn btn-info">
                View Orders
              </Link>
            </li>
            <li>
              <Link
                to="/admin/dashboard/update-password"
                className="btn btn-info"
              >
                Change Password
              </Link>
            </li>
            <li>
              <button
                type="button"
                className="btn btn-danger"
                onClick={() => handleLogout(siteContext)}
              >
                Logout
              </button>
            </li>
          </ul>
        </div>
      )}
    </div>
  );
};
export default Main;
