import React, { useState, useEffect, useMemo } from "react";
import { Link } from "gatsby";
import DataTable from "react-data-table-component";
import memoize from "memoize-one";
import { json2csvAsync } from "json-2-csv";

async function downloadCSV(array) {
  const link = document.createElement("a");

  // format 'users' and 'coursecats' into just IDs here
  const newArray = array.slice().map((row, i) => {
    row.schedules = row.schedules
      .map(x => `id:${x._pivot_schedule_id}|qty:${x._pivot_qty}`)
      .toString();
    return row;
  });
  let csv = await json2csvAsync(newArray, {
    expandArrayObjects: true,
    keys: ["namelast", "namefirst", "email"],
  });
  if (csv == null) return;

  const filename = "export.csv";

  if (!csv.match(/^data:text\/csv/i)) {
    csv = `data:text/csv;charset=utf-8,${csv}`;
  }

  link.setAttribute("href", encodeURI(csv));
  link.setAttribute("download", filename);
  link.click();
}

const Export = ({ onExport }) => (
  <div className="ml-4">
    <button
      type="button"
      onClick={e => onExport(e.target.value)}
      className="btn btn-info"
    >
      Export CSV
    </button>
  </div>
);

const delCell = memoize((row, handleDelete) => (
  <button
    type="button"
    className="btn btn-sm btn-danger"
    onClick={() => handleDelete(row.id)}
  >
    Delete
  </button>
));

const FilterComponent = ({ filterText, onFilter, onClear }) => (
  <div className="input-group" style={{ width: "300px" }}>
    <input
      id="search"
      type="text"
      placeholder="Filter By Last Name or Email"
      value={filterText}
      onChange={onFilter}
      className="form-control"
    />
    <div className="input-group-append">
      <button type="button" onClick={onClear} className="btn btn-secondary">
        X
      </button>
    </div>
  </div>
);

const purchaseCell = row => <div>{row.orders.length}</div>;

const viewCell = row => (
  <Link
    to={`/admin/dashboard/users/edit/${row.id}`}
    state={{ userData: row }}
    className="btn btn-info btn-sm"
  >
    Edit
  </Link>
);

const columns = memoize(handleDelete => [
  {
    name: "Last Name",
    selector: "namelast",
    sortable: true,
    width: "160px",
  },
  {
    name: "First Name",
    selector: "namefirst",
    sortable: false,
    width: "140px",
  },
  {
    name: "Role",
    selector: "role",
    sortable: false,
    width: "80px",
  },
  {
    name: "Email",
    selector: "email",
    sortable: true,
    width: "250px",
  },
  {
    name: "# Orders",
    selector: "orders",
    sortable: true,
    cell: row => purchaseCell(row),
  },
  {
    name: "View/Edit",
    button: true,
    cell: row => viewCell(row),
  },
  {
    name: "Delete",
    button: true,
    cell: row => delCell(row, handleDelete),
  },
]);

const UsersDataTable = ({ data, handleDelete }) => {
  const [filterText, setFilterText] = useState("");
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);

  const filteredItems =
    data &&
    data.filter(
      item =>
        (item.namelast &&
          item.namelast.toLowerCase().includes(filterText.toLowerCase())) ||
        item.email.toLowerCase().includes(filterText.toLowerCase())
    );

  const subHeaderComponentMemo = useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText("");
      }
    };

    return (
      <>
        <FilterComponent
          onFilter={e => setFilterText(e.target.value)}
          onClear={handleClear}
          filterText={filterText}
        />
        <Export onExport={() => downloadCSV(data)} />
      </>
    );
  }, [filterText, resetPaginationToggle, data]);

  return (
    data && (
      <DataTable
        title="Site Users"
        columns={columns(handleDelete)}
        data={filteredItems}
        pagination
        paginationResetDefaultPage={resetPaginationToggle}
        subHeader
        subHeaderComponent={subHeaderComponentMemo}
        responsive
        noHeader
      />
    )
  );
};

export default UsersDataTable;
