import React, { useState, useContext, useEffect } from "react";
import axios from "axios";
import { navigate } from "@reach/router";
// import { format, parseISO } from "date-fns";
import { Link } from "gatsby";

import { formatToMilitaryDate } from "helpers";
import AdminUserDetailsForm from "components/forms/AdminUserDetailsForm";
import { cartContext } from "providers/CartProvider";

const EditSchedule = ({ location, id }) => {
  const siteContext = useContext(cartContext);
  const [initialData, setInitialData] = useState({});

  useEffect(() => {
    async function fetchData(userID) {
      try {
        const token = siteContext.userToken;

        const response = await axios.get(
          `${process.env.GATSBY_API_URL}/admin-tools/user/${userID}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
            validateStatus(status) {
              return status < 500; // Resolve only if the status code is less than 500
            },
          }
        );

        setInitialData(response.data);
        // console.log("fetchData -> response.data", response.data);
      } catch (e) {
        siteContext.makeToast("Unable to fetch user details.", "error");
      }
    }

    fetchData(id);
    // console.log("EditSchedule -> id", id);
    // console.log("EditSchedule -> initialData", initialData);
  }, [siteContext, id]);

  const handleSubmit = async values => {
    // e.preventDefault();
    try {
      const response = await axios.put(
        `${process.env.GATSBY_API_URL}/admin-tools/user-update/${initialData.id}`,
        values,
        {
          headers: {
            Authorization: `Bearer ${siteContext.userToken}`,
          },
          validateStatus(status) {
            return status < 500; // Resolve only if the status code is less than 500
          },
        }
      );

      if (response && response.status === 200) {
        siteContext.makeToast("User details updated.", "success");
        return navigate("/admin/dashboard/users");
      }
      const errorMsg = response.data || "There was a problem.";
      return siteContext.makeToast(errorMsg, "error");
    } catch (e) {
      const errorMsg = "There was a problem.";
      return siteContext.makeToast(errorMsg, "error");
    }
  };

  return (
    <div>
      <h2>Admin: Edit User</h2>

      <p className="mt-3">
        <Link to="/admin/dashboard/users" className="btn btn-success">
          Cancel
        </Link>
      </p>
      {initialData.id && (
        <AdminUserDetailsForm submitIt={handleSubmit} initVals={initialData} />
      )}

      <hr />

      <div
        style={{ marginTop: "2rem", padding: "2rem", background: "#e0e0e0" }}
      >
        <h4>Purchases:</h4>
        <p style={{ fontSize: "11px" }}>
          Note: These can only be removed on the "view order" page.
        </p>
        <ul>
          {initialData.orders &&
            initialData.orders.map((s, i) => (
              <li key={i}>
                <h6>
                  <Link to={`/admin/dashboard/orders/view/${s.ordernum}`}>
                    Order #: {s.ordernum}
                  </Link>
                </h6>
                <p style={{ fontSize: "12px" }}>
                  Purchased: {formatToMilitaryDate(s.created_at)}
                </p>
              </li>
            ))}
        </ul>
      </div>
    </div>
  );
};

export default EditSchedule;
