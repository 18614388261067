import React, { useContext } from "react";
import axios from "axios";
import { navigate } from "@reach/router";
import { Link } from "gatsby";

import AdminOthereventForm from "components/forms/AdminOthereventForm";
import { cartContext } from "providers/CartProvider";

const AddOtherevent = ({ id }) => {
  const siteContext = useContext(cartContext);

  const handleSubmit = async values => {
    const response = await axios.post(
      `${process.env.GATSBY_API_URL}/admin-tools/otherevent`,
      values,
      {
        headers: {
          Authorization: `Bearer ${siteContext.userToken}`,
        },
        validateStatus(status) {
          return status < 500; // Resolve only if the status code is less than 500
        },
      }
    );

    if (response && response.status === 200) {
      siteContext.makeToast("New 'Other Event' added.", "success");
      return navigate("/admin/dashboard/otherevents");
    }

    const errorMsg = response.data || "There was a problem.";
    return siteContext.makeToast(errorMsg, "error");
  };

  return (
    <div>
      <h2>Admin: Add 'Other Event' Item</h2>

      <p className="mt-3">
        <Link to="/admin/dashboard/otherevents" className="btn btn-success">
          Cancel
        </Link>
      </p>

      <AdminOthereventForm submitIt={handleSubmit} />
    </div>
  );
};

export default AddOtherevent;
