import React from "react";
import { Formik, Field, Form, ErrorMessage } from "formik";

import { updatePasswordValidationSchema } from "helpers/validation";

const UpdatePasswordForm = ({ submitIt }) => (
  <Formik
    initialValues={{
      password: "",
      passwordconfirm: "",
    }}
    validationSchema={updatePasswordValidationSchema}
    onSubmit={(values, actions) => {
      submitIt(values);
      actions.setSubmitting(false);
    }}
  >
    {props => (
      <Form className="form">
        <div className="form-row">
          <div className="col-12 col-md-6">
            <div className="form-group">
              <label htmlFor="password" className="sr-only">
                Enter New Password:
              </label>
              <Field
                name="password"
                placeholder="New Password"
                type="password"
                className="form-control"
                required
              />
              <ErrorMessage
                name="password"
                component="div"
                className="text-danger input-feedback"
              />
            </div>
          </div>
        </div>

        <div className="form-row">
          <div className="col-12 col-md-6">
            <div className="form-group">
              <label htmlFor="password" className="sr-only">
                Re-type New Password:
              </label>
              <Field
                name="passwordconfirm"
                placeholder="Re-type New Password"
                type="password"
                className="form-control"
                required
              />
              <ErrorMessage
                name="passwordconfirm"
                component="div"
                className="text-danger input-feedback"
              />
            </div>
          </div>
        </div>

        <button type="submit" className="btn btn-primary">
          Update
        </button>
      </Form>
    )}
  </Formik>
);

export default UpdatePasswordForm;
