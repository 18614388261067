import React, { useState, useContext } from "react";
import axios from "axios";
import { Link } from "gatsby";
import { navigate } from "@reach/router";

import ResetPasswordForm from "components/forms/ResetPasswordForm";
import { cartContext } from "providers/CartProvider";

const UpdatePassword = () => {
  const siteContext = useContext(cartContext);

  const handleSubmit = async (values, actions) => {
    try {
      const response = await axios.post(
        `${process.env.GATSBY_API_URL}/users/reset-password`,
        values,
        {
          validateStatus(status) {
            return status < 500; // Resolve only if the status code is less than 500
          },
        }
      );

      if (response && response.status === 200) {
        siteContext.makeToast("Success! Please check your email.", "success");
        actions.setSubmitting(false);
        navigate("/dashboard/login");
      } else {
        actions.setSubmitting(false);
        siteContext.makeToast(
          "Error starting reset process. Did you enter the correct email?",
          "error"
        );
      }
    } catch (err) {
      actions.setSubmitting(false);
      siteContext.makeToast(
        "Server error! The password reset functionality is currently down. Please try again in a few moments.",
        "error"
      );
    }
  };

  return (
    <div>
      <h2>Reset Lost Password</h2>

      <p className="font-weight-bold">Forgot your password? No problem!</p>
      <p>
        Enter your email below, and we'll send you an email with instructions on
        resetting your password.
      </p>

      <ResetPasswordForm submitIt={handleSubmit} />

      <Link to="/dashboard/login" className="btn btn-info mt-4">
        Cancel
      </Link>
    </div>
  );
};

export default UpdatePassword;
